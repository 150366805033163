








































































































































.deposit-coin {
  max-width: 375px;
  margin: auto;
  .qr-code {
    text-align: center;
    img {
      max-width: 215px;
      margin: auto;
      width: 100%;
    }
  }
  .title {
    font-weight: 600;
    font-size: clamp(16px, 2vw, 18px);
    color: #2f394e;
    text-align: center;
    padding: 0 0 15px;
    border-bottom: 3px solid rgba($color: #000000, $alpha: 0.2);
  }
  .coin-address {
  }
  .button-close {
    button {
      font-weight: 600;
    }
  }
  .coin-warning {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
    font-size: clamp(14px, 2vw, 16px);
  }
  label {
    font-size: clamp(14px, 2vw, 16px);
    color: #6c757d;
    font-weight: 600;
    margin-bottom: 2px;
  }
  .box-address {
    width: 100%;
    span {
      font-size: clamp(14px, 2vw, 16px);
      color: #6c757d;
      font-weight: 600;
    }
    .address-wallet {
      margin-top: 2px;
      background: #FF5C00;
      border-radius: 5px;
      .input-group-text {
        padding: 0;
        border: 0;
        button {
          width: 70px;
          height: 35px;
          padding: 0;
          background: #8dc63f;
          border: 0;
          outline: none;
          border-radius: 5px;
          box-shadow: 0px 0px 5px 1px #0000009e;
          font-weight: 600;
        }
      }
      input {
        height: 35px;
        border: 0;
        background: transparent;
        color: #fff;
        font-weight: 600;
        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: 0;
        }
      }
    }
  }
}
